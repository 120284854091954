.table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  margin-top: 10px;
  background-color: var(--color-bg);
}

.tableHead {
  font-size: var(--font-size-medium);
  text-align: left;
}

.tableHead tr {
  height: 40px;
  background-color: var(--color-table-header);
}

.tableHead th {
  font-weight: normal;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.searchButton {
  cursor: pointer;
}

tr.search {
  background-color: var(--color-bg);
  border-bottom: 1px solid gray;
}
.search input {
  padding: 2px;
}

.activeSort {
  color: var(--color-primary);
}
