.header {
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name {
  font-family: var(--font-countdown);
  font-weight: 900;
}

.spacer {
  width: 1px;
  height: 25px;
  background-color: white;
  margin-left: 10px;
  margin-right: 10px;
}
