.info {
  margin-left: 10px;
  position: absolute;
  /* Size of identicon */
  left: 46px;
  top: 0;
}

.name {
  font-family: var(--font-dashboard);
  font-size: var(--font-size-medium);
  font-weight: bold;
}

.stakeLabel {
  font-family: var(--font-dashboard);
  font-size: var(--font-size-small);
}

.myStakeValue {
  font-family: var(--font-dashboard);
  font-size: var(--font-size-medium);
  color: var(--color-yellow-text);
}

.availableStakeValue {
  font-family: var(--font-dashboard);
  font-size: var(--font-size-medium);
  color: var(--color-orange-text);
}

.stakingRewardsValue {
  font-family: var(--font-dashboard);
  font-size: var(--font-size-medium);
  color: var(--color-green-text);
}

.detailsButton {
  margin-top: 5px;
}
