.footerContainer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 4px 20px;
  margin: 20px 20px 0 20px;
  background-color: var(--color-bg);
  border-top: solid 1px;
  border-top-color: rgba(102, 102, 102, 0.75);
  text-align: center;
}

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2px;
}

.name {
  font-family: var(--font-footer);
  font-size: var(--font-size-medium);
}

.reward {
  display: flex;
  padding-left: 10px;
}
.legal {
  display: flex;
  gap: 10px;
}
.legalAnchor {
  color: var(--color-primary);
  text-decoration: none;
}
.legalAnchor:hover {
  text-decoration: underline;
}

.versionInfo {
  font-family: var(--font-countdown);
  font-size: var(--font-size-smallest);
  color: var(--color-gray-alt-text);
  padding-top: 7px;
  padding-right: 90px;
  background: no-repeat center right/25% url('../../icons/Built_on_KILT.svg');
}

.gray {
  color: var(--color-gray-alt-text);
}

.red {
  color: var(--color-red-text);
}

.paddingRight {
  padding-right: 5px;
}

.spacer {
  min-width: 1px;
  height: 13px;
  background-color: var(--color-primary);
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 4px;
}
