.Select .s__control {
  background-color: var(--color-input-bg);
  border-radius: 0;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: none;
}

.Select .s__control--is-focused,
.Select .s__control--is-focused:hover {
  border-color: var(--color-primary);
}

.Select .s__input {
  color: white;
}

.Select .s__single-value {
  color: white;
}

.Select .s__menu {
  background-color: var(--color-input-bg);
  margin-top: 0;
  box-shadow: rgb(20 20 20 / 50%) 0px 8px 20px;
  border-radius: 0;
}

.Select .s__menu-list {
  padding: 0;
}

.Select .s__option--is-focused {
  background-color: var(--color-gray-button);
}

.Select .s__option--is-selected {
  background-color: var(--color-primary-light);
}

.Select .s__option:active {
  background-color: var(--color-input-border);
}
