@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/ubuntu-v20-latin-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/ubuntu-v20-latin-700.woff2') format('woff2');
}

@font-face {
  font-family: 'M PLUS Rounded 1c';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/m-plus-rounded-1c-v15-latin-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'M PLUS Rounded 1c';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/m-plus-rounded-1c-v15-latin-900.woff2') format('woff2');
}

@font-face {
  font-family: 'Asap Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/asap-condensed-v15-latin-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Asap Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/asap-condensed-v15-latin-700.woff2') format('woff2');
}

@font-face {
  font-family: 'Overpass Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Overpass-Regular.woff2') format('woff2');
}

:root {
  /* Fonts */
  --font-primary: 'Ubuntu', sans-serif;
  --font-countdown: 'M PLUS Rounded 1c', sans-serif;
  --font-dashboard: 'Asap Condensed', sans-serif;
  --font-footer: 'Overpass Regular', sans-serif;
  --font-size-big: 28px;
  --font-size-primary: 18px;
  --font-size-medium: 14px;
  --font-size-small: 12px;
  --font-size-smallest: 10px;
  --font-weight: bold;

  /* Text colours */
  --color-gray-text: #fdf7ef;
  --color-gray-alt-text: #c6c6c1;
  --color-yellow-text: #f9ee50;
  --color-orange-text: #ff9100;
  --color-green-text: #99bf42;
  --color-red-text: #cc532c;

  /* Button colours */
  --color-gray-button: #575756;
  --color-orange-button: #d75a30;

  --color-bg: #000000;
  --color-bg-paused: #e94e1b;
  --color-primary: #ffed00;
  --color-primary-light: #262305;
  --color-yellow-line: #857b00;
  --color-paused-red: #be1522;
  --color-table-header: #262626;
  --color-modal-background: #3c3c3b;
  --color-modal-border: #8b8b8a;
  --color-app-header: var(--color-table-header);
  --color-input-bg: var(--color-table-header);
  --color-top-rank: #95c11f;
  --color-low-rank: #e94e1b;
  --color-input-border: rgba(255, 255, 255, 0.3);
  --color-input-hover-border: #b3b3b3;
  --color-footer-border: rgba(102, 102, 102, 0.75);

  --color-dashboard: #0f0f1b;
  --color-staked: var(--color-primary);
  --color-claimable-rewards: #437e00;
  --color-unused: #ff9100;
  --color-withdraw: #fb8f72;
  --color-blocked: #c25639;

  --size-icon: 24px;
  --size-identicon: 46px;
  --height-table-header: 40px;
}

body {
  color: white;
  background-color: var(--color-gray-button);
  font-family: var(--font-primary);
  font-size: var(--font-size-primary);
}
