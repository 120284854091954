@import './vars.css';

.container {
  height: 40px;
  border-radius: 2px;
  background-color: white;
  border: 2px solid white;
  position: relative;
  display: inline-block;
  margin-left: var(--left-margin);
  white-space: nowrap;
}

.containerDown {
  margin-top: 111px;
}

.item {
  display: inline-block;
}

.staked {
  background-color: var(--color-staked);
  height: 100%;
  width: 20px;
}

.separator {
  width: 1px;
  top: -7px;
  bottom: -7px;
  position: absolute;
  background-color: white;
}

.separator + .stakeable {
  margin-left: 1px;
}

.stakeable {
  background-color: var(--color-unused);
  height: 100%;
  width: 20px;
}

.percentage {
  margin-left: 0;
  box-sizing: border-box;
  height: 42px;
}

.percentage .separator + .stakeable {
  margin-left: 0;
}
