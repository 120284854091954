.dashboard {
  height: 440px;
  width: 100%;
  z-index: 0;
  position: relative;
  background-color: #0f0f1b;
}

.accounts {
  margin-left: 130px;
  z-index: 2;
}

.accountsContainer {
  display: flex;
  align-items: center;
  overflow: scroll;
  width: 100%;
  height: 100%;
}

.pauseOverlay {
  background: linear-gradient(rgba(233, 78, 27, 0.3), rgba(15, 15, 27, 0.6));
  width: 100%;
  height: 100%;
}

.scaleContainer {
  position: absolute;
  bottom: 10px;
  right: 25px;
}
