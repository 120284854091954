.img {
  width: 36px;
  vertical-align: middle;
}

.pulsing {
  animation: pulsing 1s ease infinite alternate;
}

@keyframes pulsing {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
