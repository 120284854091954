.modalOverlay {
  background-color: var(--color-bg);
  opacity: 0.65;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 500;
}

.modal {
  position: fixed;
  z-index: 1002;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  background: var(--color-modal-background);
  display: flex;
  border-style: solid;
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: var(--color-modal-border);
  flex-direction: column;
  margin: 1.875rem;
  min-width: 450px;
  max-width: 650px;
  height: fit-content;
  padding: 10px 20px 50px 20px;
  color: var(--color-gray-text);
}

.textWrapper {
  padding-top: 25px;
  padding-bottom: 25px;
  max-height: 300px;
  font-size: var(--font-size-medium);
  text-align: center;
  overflow-y: auto;
}

.buttonWrapper {
  width: 215px;
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}

.buttonClose {
  background-color: var(--color-primary);
  color: var(--color-primary-light);
}

.buttonSubmit {
  background-color: var(--color-blocked);
  color: var(--color-gray-text);
}

.modalTitleWrapper {
  width: 385px;
  height: 50px;
  padding-top: 10px;
  border-bottom: 1px;
  border-color: var(--color-modal-border);
  border-bottom-style: solid;
  text-align: center;
}

.modalTitle {
  color: var(--color-gray-text);
  font-size: var(--font-size-big);
}

.errorText {
  font-family: monospace;
}

.stakeboarder {
  position: absolute;
  padding-left: 15px;
}
