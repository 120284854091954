.input {
  background-color: var(--color-input-bg);
  border: 1px solid var(--color-input-border);
  height: 28px;
  padding: 0 8px;
  color: white;
  font-family: var(--font-primary);
  font-size: var(--font-size-primary);
}

.input:hover {
  border-color: var(--color-input-hover-border);
}

.input:focus {
  border-color: var(--color-primary);
  outline: none;
}

.number {
  max-width: 110px;
  text-align: right;
}

.number::-webkit-outer-spin-button,
.number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
