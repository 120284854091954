.buttonRaw {
  cursor: pointer;
}

.button {
  color: black;
  border-radius: 5px;
  background-color: var(--color-primary);
  border: none;
  text-transform: uppercase;
  width: 90px;
  height: 18px;
  cursor: pointer;
  font-family: var(--font-countdown);
  font-size: var(--font-size-small);
}

.button:disabled {
  background-color: var(--color-gray-button);
  color: white;
}
.orange {
  background-color: var(--color-orange-button);
  color: var(--color-gray-text);
}
.green {
  background-color: var(--color-claimable-rewards);
  color: white;
}
