.container {
  width: 100%;
  height: calc(100vh);
  display: flex;
  justify-content: center;
  background-color: var(--color-bg);
}

.contents {
  max-width: 740px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: white;
  margin-top: 100px;
}

.heading {
  font-size: 40px;
  letter-spacing: 0.1;
}

.anchor {
  color: white;
  font-size: 20px;
}

.text {
  font-size: 20px;
}
