.chaininfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  background-color: var(--color-table-header);
  padding: 0 20px;
}

.chaininfoPaused {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  background-color: var(--color-bg-paused);
  padding: 0 20px;
}

.lineSpacer {
  color: var(--color-paused-red);
  font-size: var(--font-size-big);
  padding: 0 5px 0 5px;
}

.refreshPaused {
  font-size: var(--font-size-big);
  color: var(--color-gray-alt-text);
}

.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0;
}

.label {
  font-family: var(--font-primary);
  font-size: var(--font-size-small);
  text-transform: uppercase;
  color: var(--color-gray-text);
}

.labelSmallest {
  font-family: var(--font-primary);
  font-size: var(--font-size-smallest);
  text-transform: uppercase;
  color: var(--color-gray-text);
}

.leftMargin {
  margin-left: 50px;
}

.value {
  font-family: var(--font-primary);
  font-size: var(--font-size-medium);
  color: var(--color-primary);
  margin-left: 5px;
}

.valuePaused {
  font-family: var(--font-primary);
  font-size: var(--font-size-medium);
  color: var(--color-paused-red);
  margin-left: 5px;
}

.countdown {
  font-family: var(--font-countdown);
  font-size: var(--font-size-big);
  color: var(--color-primary);
  margin-left: 10px;
}

.countdownPaused {
  font-family: var(--font-countdown);
  font-size: var(--font-size-big);
  color: var(--color-paused-red);
  margin-left: 10px;
}

.onOff {
  cursor: pointer;
  margin-left: 20px;
  border-radius: 50px;
  width: 60px;
  height: 30px;
  position: relative;
}

.refreshDropdown {
  width: 100px;
  margin-left: 10px;
}
