.firstRow {
  height: 5px;
}

.lastRow {
  height: 5px;
}
.lastRow td {
  border-bottom: 1px solid gray;
}
.lastRow td:first-of-type,
.lastRow td:last-of-type {
  border: none;
}
